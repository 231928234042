import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { IDietitianAPI } from "app/core/models/dietitian/api.model";
import { DietitianAdapter } from "./dietitian.adapter.service";

@Injectable({
    providedIn: "root"
})

export class ApiAdapter implements Adapter<IDietitianAPI> {

    constructor(private adapterPackage: DietitianAdapter) {

    }

    adapt(response: any): IDietitianAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            dietitian: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

}
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { ApiAdapter } from './dietitian/api.adapter.service';
import { Dietitian } from '../models/dietitian/dietitian.model';
import { IDietitianAPI } from '../models/dietitian/api.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppLoaderService } from './app-loader.service';

@Injectable({
  providedIn: 'root'
})

export class DietitianService {

  private currentDietitianSubject = new BehaviorSubject<Dietitian>({} as Dietitian);
  public currentDietitian = this.currentDietitianSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
    private snackBar: MatSnackBar,  
    private loader: AppLoaderService,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentDietitianSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(formData): Observable<IDietitianAPI> {
    return this.apiService.post('dietician/login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  otpVerify(formData): Observable<IDietitianAPI> {
    return this.apiService.post('dietician/login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  forgetpassword(formData): Observable<IDietitianAPI> {
    return this.apiService.post('forget', formData).pipe(
      catchError((error) => {
        let errorMessage = 'An unexpected error occurred. Please try again later.';
        if (error.status === 500) {
          errorMessage = 'Internal Server Error. Something went wrong.';
        } else if (error.status === 404) {
          errorMessage = 'Resource not found. Please check your request.';
        } 
        this.snackBar.open(errorMessage, 'Close', {
          duration: 5000,
        });
        this.loader.close();
        return of(null);
      })
    );
  }


  setAuth(Dietitian: Dietitian) {
    // Save JWT sent from server in localstorage
    saveToken(Dietitian.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(Dietitian));
    // Set current Dietitian data into observable
    this.currentDietitianSubject.next(Dietitian);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentDietitianSubject.next({} as Dietitian);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: Dietitian): Observable<IDietitianAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('forget', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }


  resetPassword(formData): Observable<IDietitianAPI> {
    return this.apiService.post('reset', formData).pipe(
      catchError((error) => {
        let errorMessage = 'An unexpected error occurred. Please try again later.';
        if (error.status === 500) {
          errorMessage = 'Internal Server Error. Something went wrong.';
        } else if (error.status === 404) {
          errorMessage = 'Resource not found. Please check your request.';
        } 
        this.snackBar.open(errorMessage, 'Close', {
          duration: 5000,
        });
        this.loader.close();
        return of(null);
      })
    );
  }


  chagePassword(model: Dietitian): Observable<IDietitianAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('changepassword', formData).pipe(
      catchError((error) => {
        let errorMessage = 'An unexpected error occurred. Please try again later.';
        if (error.status === 500) {
          errorMessage = 'Internal Server Error. Something went wrong.';
        } else if (error.status === 404) {
          errorMessage = 'Resource not found. Please check your request.';
        } 
        this.snackBar.open(errorMessage, 'Close', {
          duration: 5000,
        });
        this.loader.close();
        return of(null);
      })
    );
  }



  getCurrentUser(): Dietitian {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): Dietitian {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }

  goToLogin() {
    if (this.portalType == PORTALTYPE.GYM) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.DIETITIAN) {
      this.router.navigate(['/dietitian']);
    } else if (this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
      this.router.navigate(['/physiotherapist']);
    } else {
      this.router.navigate(['/']); //default
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }



}
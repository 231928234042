import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { Dietitian } from "app/core/models/dietitian/dietitian.model";


@Injectable({
    providedIn: "root"
})

export class DietitianAdapter implements Adapter<Dietitian> {
   model: Dietitian;
    adapt(item: any): Dietitian {
        this.model =  new Dietitian();    
        this.model.id = item?.id ?? 0,
        this.model.name = item?.name ?? '';
        this.model.email = item?.aud ?? '';
        this.model.mobile = item?.mobile ?? '';
        this.model.token = item?.token ?? '';
        this.model.skybind = item?.rolepermission ?? '';
        this.model.roleId = item?.role_id ?? '';
        this.model.otp = item?.otp ?? '';
        return this.model;
    }
}
import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { Physio } from "app/core/models/physio/physio.model";


@Injectable({
    providedIn: "root"
})

export class PhysioAdapter implements Adapter<Physio> {
   model: Physio;
    adapt(item: any): Physio {
        this.model =  new Physio();    
        this.model.id = item?.id ?? 0,
        this.model.name = item?.name ?? '';
        this.model.email = item?.aud ?? '';
        this.model.mobile = item?.mobile ?? '';
        this.model.token = item?.token ?? '';
        this.model.skybind = item?.rolepermission ?? '';
        this.model.roleId = item?.role_id ?? '';
        this.model.otp = item?.otp ?? '';
        return this.model;
    }
}
import { Injectable } from "@angular/core";
import { ITrainerAPI } from "app/core/models/trainer/api.model";
import { Adapter } from "../../adapters";
import { TrainerAdapter } from "./trainer.adapter.service";
@Injectable({
    providedIn: "root"
})
export class ApiAdapter implements Adapter<ITrainerAPI> {
    constructor(private adapterPackage: TrainerAdapter) {
    }
    adapt(response: any): ITrainerAPI {
        let userData = this.adapterPackage.adapt(response.data);
        return {
            keyword: response.keyword,
            message: response.message,
            trainer: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { DietitianService } from './dietitan.service';

@Injectable({
  providedIn: 'root'
})
export class DietitianAuthGuard implements CanActivate {
  constructor(
    private dietitianService: DietitianService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.dietitianService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        if (!canLogin) {
          // this.router.navigate(['/']); 
          this.dietitianService.logout();
          return false;
        }
        return true;
      })
    );
  }
}
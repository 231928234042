import { Injectable } from "@angular/core";
import { IPhysioAPI } from "app/core/models/physio/api.model";
import { Adapter } from "../../adapters";
import { PhysioAdapter } from "./physio.adapter.service";

@Injectable({
    providedIn: "root"    
})
 
export class ApiAdapter implements Adapter<IPhysioAPI> {
    constructor(private adapterPackage: PhysioAdapter) {
    } 
    adapt(response: any): IPhysioAPI {
        let userData = this.adapterPackage.adapt(response.data);
        return {
            keyword: response.keyword,
            message: response.message,
            physio: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }
}
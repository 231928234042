import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { TrainerService } from './trainer.service';

@Injectable({
  providedIn: 'root'
})
export class TrainerAuthGuard implements CanActivate {
  constructor(
    private trainerService: TrainerService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.trainerService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        if (!canLogin) {
          // this.router.navigate(['/']); 
          this.trainerService.logout();
          return false;
        }
        return true;
      })
    );
  }
}
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { Physio } from '../models/physio/physio.model';
import { ApiAdapter } from './physio/api.adapter.service';
import { IPhysioAPI } from '../models/physio/api.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppLoaderService } from './app-loader.service';


@Injectable({
  providedIn: 'root'
})

export class PhysioService {

  private currentPhysioSubject = new BehaviorSubject<Physio>({} as Physio);
  public currentPhysio = this.currentPhysioSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
    private snackBar: MatSnackBar,  
    private loader: AppLoaderService,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentPhysioSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(formData): Observable<IPhysioAPI> {
    return this.apiService.post('physio/login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  otpVerify(formData): Observable<IPhysioAPI> {
    return this.apiService.post('physio/login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  forgetpassword(formData): Observable<IPhysioAPI> {
    return this.apiService.post('forget', formData).pipe(
		  catchError((error) => {
			let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  errorMessage = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  errorMessage = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(errorMessage, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);
  }


  setAuth(Physio: Physio) {
    // Save JWT sent from server in localstorage
    saveToken(Physio.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(Physio));
    // Set current Physio data into observable
    this.currentPhysioSubject.next(Physio);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentPhysioSubject.next({} as Physio);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: Physio): Observable<IPhysioAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('forget', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        errorMessage = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        errorMessage = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }


  resetPassword(formData): Observable<IPhysioAPI> {
    return this.apiService.post('reset', formData).pipe(
		  catchError((error) => {
			let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  errorMessage = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  errorMessage = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(errorMessage, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);
  }


  chagePassword(model: Physio): Observable<IPhysioAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('changepassword', formData).pipe(
		  catchError((error) => {
			let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  errorMessage = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  errorMessage = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(errorMessage, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);
  }



  getCurrentUser(): Physio {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): Physio {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }

  goToLogin() {
    if (this.portalType == PORTALTYPE.GYM) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.DIETITIAN) {
      this.router.navigate(['/dietitian']);
    } else if (this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
      this.router.navigate(['/physiotherapist']);
    } else {
      this.router.navigate(['/']); //default
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }



}
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(private router: Router) { }

  goToDashboard() {
    if (this.portalType == PORTALTYPE.GYM) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.DIETITIAN) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
      this.router.navigate(['/']);
    }
  }

  get portalType() {
    const token = decodedToken();
    return get(token, 'type') ?? '';
  }

}
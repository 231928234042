<div class="text-center mat-bg-primary pt-1 pb-1">
  <h6 class="m-0">Notifications</h6>
</div> 
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngFor="let n of notifications | slice:0:15; let i = index" class="notific-item" role="listitem">
    <!-- <mat-icon [color]="n.color" class="notific-icon mr-1">{{n.icon}}</mat-icon> -->
    <!-- <img [src]="n.page ? 'assets/images/notification-icons/' + n.page + '.png' : 'assets/images/notification-icons/sample.png'" width="30" height="30"> -->
    <img [src]="'assets/images/notification-icons/sample.png'" width="30" height="30">
    <a (click)="clicked(i,n)">
      <div class="mat-list-text">
        <h4 class="message text-left pl-12" style="text-align:left;">{{n.title}}</h4>
        <small class="time text-muted pl-12">{{ n.created_on ? (n.created_on | timeago:live) : ( dateNumber | timeago:live) }}</small>
      </div>
    </a> 
    <br>
  </mat-list-item>
</mat-nav-list>
<!-- *ngIf="notifications.length" -->
<div class="text-center mt-1">
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px;">View More</a></small>
</div>